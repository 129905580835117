import React from "react"

// Libraries
import styled from "styled-components"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { colors } from "utils/variables"
import { Link } from "gatsby"

// Components
import CategoryBadge from "components/category-badge"

const BlogCardStyles = `
  height: 100%;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;

  &:hover {
    box-shadow: 2px 16px 40px rgba(110, 200, 205, 0.3);

    .h3 {
      color: ${colors.turquoise__primary};
      transition: color 30ms linear;
    }
    }
  }

  .image {
    width: 100%;
    height: 200px;
  }

  .card__content {
    height: calc(100% - 240px);
    padding: 32px;
    display: flex;
    flex-wrap: wrap;
  }

  .categories__container {
    display: flex;
    align-self: flex-end;
    flex-wrap: wrap;
  }

  .excerpt__container {
    margin-bottom: 32px;
    p {
      color: ${colors.navy__extra_dark};
    }
  }
`

const InternalBlogCard = styled(Link)`
  ${BlogCardStyles}
`

const ExternalBlogCard = styled.a`
  ${BlogCardStyles}
`

const BlogCard = (props) => {
  const {
    title,
    blogCategories,
    featuredImage,
    excerpt,
    slug,
    excerptValidation,
    external,
  } = props
  const image = getImage(featuredImage)

  if (external) {
    return (
      <ExternalBlogCard
        href={`/blog/${slug}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div className="card__image">
          <GatsbyImage className="image w-100" image={image} alt={image} />
        </div>
        <div className="card__content">
          <p className="h3 mb-2">{title}</p>
          {excerptValidation && (
            <div className="excerpt__container">
              <p>
                {excerpt === null
                  ? "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula natoque penatibus et magnis dis.."
                  : excerpt.excerpt}
              </p>
            </div>
          )}
          <div className="categories__container">
            {blogCategories &&
              blogCategories.map((category) => (
                <CategoryBadge
                  className="me-2 mb-2"
                  to={`/blog/categories/${category.slug}`}
                >
                  {category.title}
                </CategoryBadge>
              ))}
          </div>
        </div>{" "}
      </ExternalBlogCard>
    )
  }
  return (
    <InternalBlogCard to={`/blog/${slug}`}>
      <div className="card__image">
        <GatsbyImage className="image w-100" image={image} alt={image} />
      </div>
      <div className="card__content">
        <p className="h3 mb-2">{title}</p>
        {excerptValidation && (
          <div className="excerpt__container">
            <p>
              {excerpt === null
                ? "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula natoque penatibus et magnis dis.."
                : excerpt.excerpt}
            </p>
          </div>
        )}
        <div className="categories__container">
          {blogCategories &&
            blogCategories.map((category) => (
              <CategoryBadge
                className="me-2 mb-2"
                to={`/blog/categories/${category.slug}`}
              >
                {category.title}
              </CategoryBadge>
            ))}
        </div>
      </div>{" "}
    </InternalBlogCard>
  )
}
export default BlogCard

BlogCard.propTypes = {
  title: PropTypes.string.isRequired,
  blogCategories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  featuredImage: PropTypes.shape({}).isRequired,
  excerpt: PropTypes.shape({ excerpt: PropTypes.string.isRequired }).isRequired,
  slug: PropTypes.string.isRequired,
  excerptValidation: PropTypes.bool,
  external: PropTypes.bool,
}

BlogCard.defaultProps = {
  excerptValidation: true,
  external: false,
}

// Libraries
import { Link } from "gatsby"
import { transparentize } from "polished"
import styled from "styled-components"
import { colors } from "utils/variables"

const CategoryBadge = styled(Link)`
  display: inline-block;
  padding: 8px 12px;
  background-color: ${transparentize(0.9, colors.turquoise__primary)};
  color: ${colors.turquoise__primary};
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  border-radius: 20px;

  &:hover {
    background-color: ${colors.turquoise__primary};
    color: ${colors.white};
  }
`

export default CategoryBadge

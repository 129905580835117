import React from "react"

// Components
import Posts from "components/posts"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Utils
import { colors } from "utils/variables"

const StyledAllPosts = styled.div`
  padding-top: 98px;
  width: 100%;
  background-color: ${colors.silver__pale};
`

const AllPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPosts(sort: { order: DESC, fields: publishDate }) {
        nodes {
          title
          slug
          featuredImage {
            gatsbyImageData(width: 352, height: 200)
          }
          excerpt {
            excerpt
          }
          blogCategories {
            title
            slug
          }
        }
      }
    }
  `)
  return (
    <StyledAllPosts>
      {data.allContentfulBlogPosts.nodes && (
        <Posts data={data.allContentfulBlogPosts.nodes} />
      )}
    </StyledAllPosts>
  )
}

export default AllPosts

import React from "react"

// Libraries
import styled from "styled-components"
import Carousel from "react-slick"

// Styles
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "components/button"
import { colors } from "utils/variables"

const StyledFeaturedPosts = styled.section`
  max-height: -webkit-fill-available;
  min-height: calc(100vh - 62px);
  display: flex;
  align-items: center;
  padding: 60px 0;

  ${breakpoint.medium`
    min-height: calc(100vh - 96px - 58px);
  `}

  ${Container} {
    padding: 0 25px;
  }

  .featured-posts__carousel {
    .slick-dots {
      ${breakpoint.small`
        text-align: left;
      `}
      li {
        width: 8px;
        height: 8px;

        &.slick-active {
          button {
            background-color: ${colors.turquoise__light};
          }
        }

        button {
          width: 100%;
          height: 100%;
          background-color: ${colors.silver__light};
          border-radius: 50%;

          &::before {
            content: none;
            display: none;
          }
        }
      }
    }

    .author {
      &::after {
        content: "·";
        margin: 0 8px;
      }
    }
  }
`

const FeaturedPosts = () => {
  const {
    allContentfulBlogPosts: { nodes: posts },
  } = useStaticQuery(graphql`
    query {
      allContentfulBlogPosts(
        filter: { featuredPost: { eq: true } }
        limit: 3
        sort: { fields: publishDate, order: DESC }
      ) {
        nodes {
          id
          title
          slug
          excerpt {
            excerpt
          }
          featuredImage {
            gatsbyImageData(width: 544, quality: 100)
          }
          author {
            name
          }
          publishDate(formatString: "MMM D, YYYY")
        }
      }
    }
  `)

  const carouselSettings = {
    fade: true,
    speed: 600,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
  }

  return (
    <StyledFeaturedPosts>
      <Container>
        <Carousel {...carouselSettings} className="featured-posts__carousel">
          {posts.map((post) => (
            <div
              className="row d-flex align-items-center justify-content-between"
              key={post.title}
            >
              <div className="col-12 col-sm-6 mb-4 mb-sm-0">
                <div className="featured-post__image">
                  <GatsbyImage
                    image={getImage(post.featuredImage)}
                    style={{ aspectRatio: "4 / 3" }}
                    imgStyle={{ borderRadius: "16px" }}
                    alt=""
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-5">
                <div className="featured-post__content">
                  <h2 className="h2 maxi mb-2">{post.title}</h2>

                  <p className="text--large mb-3 color--silver__dark">
                    {post.excerpt
                      ? post.excerpt.excerpt
                      : "With Arena Analytics, you can seamlessly solve your workplace challenges from hiring to retention."}
                  </p>

                  <p className="text--large">
                    {post.author && (
                      <span className="author">{post.author.name}</span>
                    )}
                    <span className="reading-time">3 Min Read</span>
                  </p>
                  <p className="color--silver__primary mt-1">
                    {post.publishDate}
                  </p>

                  <Button
                    type="link"
                    to={`/blog/${post.slug}`}
                    className="mt-4"
                  >
                    Read Article
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </Container>
    </StyledFeaturedPosts>
  )
}

export default FeaturedPosts

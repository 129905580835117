// Reac
import React from "react"

// Libraries
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"

// Utils
import { colors, fonts } from "utils/variables"
import breakpoints from "utils/breakpoints"

// Components
import Container from "components/container/"

const StyledBlogCategories = styled.section`
  background: ${colors.silver__extra_pale};
  padding: 60px 0;

  ${breakpoints.medium`
    padding: 120px 0; 
  `}

  .title {
    margin-bottom: 40px;
  }

  .category-card {
    display: flex;
    align-items: center;
    line-height: 28px;
    font-family: ${fonts.secondary};
    border-radius: 16px;
    box-shadow: 0px 4px 13px rgba(147, 159, 171, 0.15);
    background-color: white;
    padding: 32px;
    height: 100%;

    ${breakpoints.small`
        max-width: 351px;
        width: 100%;
    `}

    img {
      margin-right: 24px;
    }
  }
`

const BlogCategories = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogCategory {
        nodes {
          title
          slug
          icon {
            url
          }
        }
      }
    }
  `)

  return (
    <StyledBlogCategories>
      <Container>
        <h2 className="h1 title text-center">Explore other categories</h2>
        <div className="row">
          {data.allContentfulBlogCategory.nodes.map((node) => (
            <div className="col-12 col-sm-6 col-md-4 mb-4">
              <Link
                href={`/blog/categories/${node.slug}`}
                className="category-card text--large font-weight--700"
              >
                <img src={node.icon.url} height={64} width={64} alt="" />
                {node.title}
              </Link>
            </div>
          ))}
        </div>
      </Container>
    </StyledBlogCategories>
  )
}

export default BlogCategories

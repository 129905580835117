import React from "react"

// Layouts
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo"

// Sections
import FeaturedPosts from "sections/blog/featured-posts"
import AllPosts from "components/all-blog-cards"
import BlogCategories from "components/blog-categories"

const Blog = () => (
  <Layout>
    <SEO title="Blog | Arena Analytics" />
    <FeaturedPosts />
    <AllPosts />
    <BlogCategories />
  </Layout>
)

export default Blog

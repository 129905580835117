import React, { useState } from "react"

// Libraries
import PropTypes from "prop-types"

// Components
import BlogCard from "components/blog-card"
import Container from "components/container"
import Button from "components/button"

// Libraries
import styled from "styled-components"

// Utils
import { colors } from "utils/variables"

// Icons
import Chevrondown from "assets/icons/icon-chevron-down.inline.svg"

const StyledAllBlogCards = styled.section`
  width: 100%;
  background-color: ${colors.silver__pale};

  .card {
    padding: 16px;
  }

  .cards__section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loadmore__container {
    margin-top: 64px;
    margin-bottom: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .loadmore__button {
    width: 234px;
    background-color: ${colors.silver__extra_pale};
    svg {
      margin-left: 8px;

      * {
        stroke: ${colors.turquoise__primary}
      }
  }
`

const Posts = ({ data }) => {
  const [resultsPerSection, setResultsPerSection] = useState(6)

  const postToShow = data.slice(0, resultsPerSection)
  const onHandleClick = () => {
    setResultsPerSection(resultsPerSection + 6)
  }
  return (
    <StyledAllBlogCards>
      <Container>
        <div className="cards__section">
          <div className="row">
            {postToShow.map((post) => (
              <div className="card col-12 col-sm-6 col-md-4">
                <BlogCard {...post} />
              </div>
            ))}
          </div>
          <div className="loadmore__container">
            {data.length >= resultsPerSection && (
              <Button
                type="button"
                onClick={() => onHandleClick()}
                level="tertiary"
                className="button loadmore__button"
              >
                Load more articles
                <Chevrondown />
              </Button>
            )}
          </div>
        </div>
      </Container>
    </StyledAllBlogCards>
  )
}

export default Posts

Posts.propTypes = {
  data: PropTypes.shape({
    slice: PropTypes.func.isRequired,
    length: PropTypes.func.isRequired,
  }).isRequired,
}
